import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';

function Stepper({
	classes,
	steps = ['PERSONAL DATA', 'SOCIAL MEDIA', 'CREATE ACCOUNT'],
	currentStep,
	className,
	onStepClick = () => {},
}) {
	const Step = (value, i) => (

		<div className={classes.step}>
			<p
				onClick={() => onStepClick(i)}
				className={[classes.index, i < currentStep && classes.prev].join(' ')}
			>
				{i + 1}
			</p>
			<div className={[
				classes.divider,
				i === currentStep && classes.dividerSelected,
			].join(' ')}
			/>
			<p className={[
				classes.text,
				i === currentStep && classes.selected,
			].join(' ')}
			>
				{value}

			</p>
		</div>
	);

	return (

		<div className={[classes.root, className].join(' ')}>
			{steps.map(Step)}
		</div>
	);
}

export default withStyles(styles)(Stepper);
