export default (theme) => ({
	TextStyle: {
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'baseline',
	},
	FirstText: {
		textTransform: 'uppercase',
		fontSize: '1.5em',
		color: theme.palette.black,
		fontFamily: theme.typography.header.solid,
		// margin: '-12px 0',
		// marginTop: '-2px',
		// width: 300,
		// textAlign: 'initial',
	},
	SecondText: {
		textTransform: 'uppercase',
		fontSize: '1.5em',
		color: theme.palette.black,
		fontFamily: theme.typography.header.outline,
		margin: '-12px 0',
		// marginTop: '-2px',
		marginLeft: '2%',
		// textAlign: 'end',
	},
	TextStyleLeft: {
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'left',
	},
});
