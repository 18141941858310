// const url = 'http://localhost:4502/login';
const API = 'http://localhost:4400/pyro/';

function getData(data, service) {
	// console.log( data );
	// let  { email,password } = data;
	// let formData = new FormData();
	// formData.append('email', email);
	// formData.append('language', 'En');
	// formData.append('password', password);
	// // let req = new XMLHttpRequest();
	// // console.log( formData );
	// // req.open('POST', url);
	// // req.send( formData );
	// console.log( formData );
	// fetch(url, {
	//     method: 'POST',
	//     headers: {
	//         'Content-Type': 'application/x-www-form-urlencoded'
	//     },
	//     body: formData
	// })
	// .then( resp => {
	//     console.log( resp );
	// })
	// .catch( err => {
	//     console.log( err );
	// })
	if (service === 'reset') {
		const u = `${API}resetpassword`;

		fetch(u, {
			crossDomain: true,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				email: 'support@bquate.com',
			},
		})
			.then(() => {

			})
			.catch(() => {

			});
	}
}

module.exports = getData;
