export default () => ({

	// styles
	container: {
		display: 'flex',
		height: '90vh',
		justifyContent: 'space-between',
		width: '100%',
		background: '#000',
		marginTop: '-1px',
	},
});
