import React, { Component } from 'react';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import Twitter from 'assets/img/Twitter.svg';
import Instagram from 'assets/img/intagram.svg';
import Youtube from 'assets/img/Youtube.svg';
import Facebook from 'assets/img/Facebook.svg';
import styles from './styles.js';

class Info extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.divs}>
				<div className={classes.titles}>
					<div className={classes.true}>YOUR</div>
					<div className={classes.inde}>MUSIC</div>
					<div className={classes.spirit}>EVERYWHERE</div>
				</div>
				<div className={[classes.divf, ' ', classes.marginTop].join('')}>
					<ul className={classes.ul}>
						<li className={classes.li}>
							Share your music on Spotify, Apple Music, Google Music, Deezer and
							a wide network of popular music stores.
						</li>
						<li className={classes.li}>
							Transparent information and complete reports.
						</li>
					</ul>
				</div>
				<div className={[classes.div, ' ', classes.now].join('')}>
					<strong>APPLY NOW</strong>
					, we're your new partners!
				</div>
				<div className={classes.div}>
					<Link to="/apply">
						<button className={classes.btn}>APPLY</button>
					</Link>
				</div>
				<div className={classes.socialMedia}>
					<span className={classes.follow}>FOLLOW</span>
					<div className={classes.line} />
					<div className={classes.icon}>
						<a href="#' target='_blank">
							<img src={Twitter} alt="icon" className={classes.icons} />
						</a>
						<a
							href="/"
							target="_blank"
							rel="noopener noreferer"
						>
							<img src={Youtube} alt="" className={classes.icons} />
						</a>
						<a
							href="https://www.instagram.com/pyrodistro/?igshid=yus7d4n33zte"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={Instagram} alt="" className={classes.icons} />
						</a>
						<a
							href="/"
							target="_blank"
						>
							<img src={Facebook} alt="icons" className={classes.icons} />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(Info);
