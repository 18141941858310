import React from 'react';
import { connect } from 'react-redux';
import showResults from './Wizard/showResults';

import WizardForm from './Wizard/WizardForm';
import NavBar from '../presentational/Utils/Navbar';


const ApplyContainer = () => {
	return (
		<>
			<NavBar />
			<WizardForm onSubmit={showResults} />
		</>
	);
};


export default connect((state) => {
	return { user: state.user };
})(ApplyContainer);
