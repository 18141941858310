const redColor = '#D40C28';

export default () => ({
	apply: {
		color: redColor,
	},
	now: {
		color: '#FFF',
		marginTop: '35px',
		fontFamily: 'Graphik',
		fontSize: '16px',
		textAlign: 'center',
	},
	btn: {
		backgroundColor: redColor,
		border: 'none',
		color: '#FFF',
		cursor: 'pointer',
		fontSize: '14px',
		marginTop: '35px',
		outline: 'none',
		padding: '14px 75px',
	},
	div: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
		width: '90%',
	},
	ul: {
		textAlign: 'left',
		width: '80%',
	},
	li: {
		color: '#fff',
		fontFamily: 'Graphik',
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '90%',
		margin: '20px 0px',
		marginLeft: '-20px',
	},
	divs: {
		padding: '0px',
		backgroundColor: '#000',
	},
	socialMedia: {
		alignItems: 'center',
		backgroundColor: '#000',
		fontFamily: 'Graphik',
		fontSize: '12px',
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '10px',
		position: 'absolute',
		width: '35%',
	},
	marginTop: {
		marginTop: '15px',
	},
	true: {
		color: '#FFF',
		fontFamily: 'Ultra System Sans',
		fontSize: '40px',
		fontWeight: '900',
		marginTop: '40%',
	},
	inde: {
		color: '#FFF',
		fontFamily: 'Ultra System Sans Outline',
		fontSize: '40px',
		fontWeight: '900',

	},
	spirit: {
		color: '#FFF',
		fontFamily: 'Ultra System Sans',
		fontSize: '40px',
		fontWeight: '900',
		textAlign: 'right',
	},
	titles: {
		width: '58%',
	},
	line: {
		height: '2px',
		backgroundColor: redColor,
		width: '25px',
		margin: '0px 15px',
	},
	icons: {
		margin: '0px 10px',
		width: '20px',
		height: '20px',
		cursor: 'pointer',
	},
	follow: {
		color: '#FFF',
	},
});
