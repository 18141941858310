import React, { Component } from 'react';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import styles from './styles.js';
import logo from './logo.svg';
import pbb from './pbb-white.svg';

class Navbar extends Component {
	render() {
		const { classes, login } = this.props;
		return (
			<>
				<div className={classes.navbar}>
					<Link to="/">
						<img src={logo} alt="" />
					</Link>
					<div className={classes.btnLogo}>
						{login
							? (
								<Link to="/login">
									<button className={classes.login}>LOGIN</button>
								</Link>
							)
							: ''}
						<img src={pbb} alt="" className={classes.pbb} />
					</div>
				</div>
			</>
		);
	}
}

export default withStyles(styles)(Navbar);
