const redMain = '#d40c24';

export default () => ({
	navbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '6.25em',
		padding: '0 42px',
		borderBottom: '1px solid rgb(210, 210, 211)',
		backgroundColor: '#000000',
	},
	btnLogo: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#000',
	},
	title: {
		color: 'red',
	},
	login: {
		backgroundColor: '#000',
		color: redMain,
		border: `1px solid ${redMain}`,
		padding: '10px 40px',
		fontSize: '14px',
		cursor: 'pointer',
		outline: 'none',
		height: '50%',
		marginRight: '15px',
		textAlign: 'center',
	},
	pbb: {
		width: '150px',
	},
});
