export default () => ({
	div: {
		width: '60%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: '#000',
	},
	img: {
		height:"80%"
	}
	// slider: {
	//     width: '50%',
	//     height: '60%',
	//     background: 'url(../../../src/one.jpg)',
	//     backgroundSize: 'cover',
	// }
});
