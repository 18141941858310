const redColor = '#D40C24';
export default () => ({
	p: {
		color: '#474747',
		fontSize: '12px',
		fontFamily: 'Graphik',
		width: '90%',
		textAlign: 'left',
	},
	textRed: {
		color: redColor,
		fontSize: '12px',
		fontFamily: 'Graphik',
		textDecoration: 'underline',
	},
	inputs: {
		border: 'none',
		borderBottom: '1px solid #474747',
		outline: 'none',
		padding: '10px 0px',
		width: '100%',
		marginBottom: '20px',
		marginTop: '10px',
	},
});
