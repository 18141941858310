import React, { useState } from 'react';
import withStyles from 'react-jss';
import { Link } from 'react-router-dom';
import { Grid } from 'react-flexbox-grid';
import lock from 'assets/img/email_reset_password.png';
import close from 'assets/img/close_secundary.svg';
import styles from './styles';
import Navbar from '../Utils/Navbar/index';
import Service from '../../../Services/Service';

const Login = (props) => {
	const { classes } = props;
	const [visible, setVisible] = useState(false);
	const [password, setPassword] = useState('');
	const [resetPassword, setReset] = useState('');

	function resetPass() {
		Service(resetPassword, 'reset');
	}
	return visible ? (
		<div className={classes.bgScreen}>
			<Grid xs={9} sm={3} md={6} lg={9} className={classes.whiteContainer}>
				<div className={classes.headerContainer}>
					<div className={classes.titleCont}>
						<div>
							<img
								style={{ height: '24px', width: '24px', marginRight: '20px' }}
								src={lock}
								alt="lock-confirm"
							/>
						</div>
						<div>
							<span className={classes.blackTitle}>RESET</span>
							<span className={classes.redTitle}>your password</span>
						</div>
					</div>

					<div>
						<img
							style={{
								height: '24px',
								width: '24px',
								marginLeft: '30px',
								cursor: 'pointer',
							}}
							src={close}
							alt="close"
							onClick={() => setVisible(false)}
						/>
					</div>
				</div>
				<section className={classes.infoContainer}>
					<div style={{ marginBottom: '2em' }}>
						<p className={classes.info}>
              Enter your email address and we’ll send you a link to reset your
              password.
						</p>
					</div>
					<div className={classes.inputContainer}>
						<label className={classes.label}>Email* </label>
						<input
							className={classes.inputs}
							type="email"
							value={resetPassword}
							onChange={(e) => setReset(e.target.value)}
						/>
					</div>
					<div>
						<button
							className={classes.whiteBtn}
							onClick={() => setVisible(false)}
						>
              BACK TO LOG IN
						</button>
						{' '}
						<button className={classes.btn} onClick={resetPass}>SEND</button>
					</div>
				</section>
			</Grid>
		</div>
	) : (
		<div>
			<Navbar />
			<div className={classes.main}>
				<h1 className={classes.title}>WELCOME!</h1>
				<p className={classes.p}>
          You're one click away from experiencing
					{' '}
					<strong>True Independent Spirit.</strong>
				</p>
				<div className={classes.required}>
					<sup>*</sup>
Required field
				</div>
				<div className={classes.divInputs}>
					<form autoComplete="off" action="/login" method="POST">
						<label htmlFor="" className={classes.label}>
              Email
						</label>
						<input
							type="text"
							autoComplete="ño"
							className={classes.inputs}
							// onChange={(e) => setEmail(e.target.value)}
							name="email"
						/>
						<br />
						<label htmlFor="" className={classes.label}>
              Password
						</label>
						<input
							type="password"
							autoComplete="off"
							className={classes.inputs}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							name="password"
						/>
						<div className={classes.divBtn}>
							<button className={classes.btn} type="submit">LOG IN</button>
						</div>
					</form>
				</div>
				<div />
				<div>
					<p className={classes.actions}>
						<>
							<span className={classes.red} onClick={() => setVisible(true)}>
                &nbsp;Forgot your password?
							</span>
						</>
            &nbsp; | Don't have account?
						<Link to="/apply" style={{ textDecoration: 'none' }}>
							<span className={classes.red}>&nbsp; Sign up</span>
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(Login);
