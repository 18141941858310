import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';

const Button = ({ classes, children }) => {
	return (
		<button className={[classes.root, classes.primary].join(' ')} type="submit">{children}</button>
	);
};

export default withStyles(styles)(Button);
