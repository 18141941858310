import React from 'react';
// Redux form
import { Field, reduxForm } from 'redux-form';

// Form components
import { Grid, Col, Row } from 'react-flexbox-grid';
import { required, matchPassword, email } from 'Components/containers/Wizard/validations/validate';
import { usePreview } from '../../../../hooks/index';
import InputField from '../Components/InputField';
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';
import PassWordInput from '../Components/PasswordInput';
import Button from '../Components/Button';
import InputFile from '../Components/InputFile';

const CreateAccount = (props) => {
	const { handleSubmit, pristine, submitting } = props;
	const [url, handlePreview] = usePreview();


	const handleInputChange = (e) => {
		handlePreview(e);
	};


	return (
		<section style={{ marginTop: '3.125em' }}>
			<Grid>
				<Row center="xs">
					<Col xs={12} sm={12} md={4} lg={4}>
						<Title FirstSentence="Create" SecondSentence=" your Account" />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle
							Text="Enter your data to create your profile and get an answer once "
						>
							{'PYRO'}
						</Subtitle>
						<Subtitle
							Text=" reviews your project. "
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<Row center="xs">
							<Col xs={6} sm={6} md={6} xl={6}>
								<form onSubmit={handleSubmit}>
									<Row>
										<Col md={12}>
											<Field
												name="email"
												component={InputField}
												label="Email *"
												type="email"
												validate={[required, email]}
											/>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<Field
												type="password"
												name="password"
												component={PassWordInput}
												placeholder="password *"
												validate={[required]}
											/>
										</Col>
										<Col md={6}>
											<Field
												type="password"
												name="confirmPassword"
												component={PassWordInput}
												placeholder="confirm password *"
												validate={[required, matchPassword]}
											/>
										</Col>
									</Row>
									<p style={{ textAlign: 'start' }}>Profile Picture</p>
									<Field
										label="CHOOSE A FILE"
										name="file"
										component={InputFile}
										type="file"
										onChange={(e) => handleInputChange(e)}
										url={url}
										value={url}
									/>
									<div>
										<Button type="submit" disabled={pristine || submitting}>Submit</Button>
									</div>
								</form>
							</Col>
						</Row>
					</Col>
				</Row>
			</Grid>
		</section>
	);
};

export default reduxForm({
	form: 'wizard', //                 <------ same form name
	destroyOnUnmount: false, //        <------ preserve form data
	forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CreateAccount);
