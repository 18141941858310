import React from 'react';
import withStyles from 'react-jss';
import Icon from 'assets/img/nota_production.svg';
import styles from './styles';


const InputFile = ({
	input, label, url, value, type, meta: { touched, error }, classes,
}) => {
	return (
		<div className={classes.root}>
			<div className={classes.imgContainer}>
				<img src={url === null ? Icon : url} alt="icon" />
			</div>
			<div className={classes.inputContainer}>
				<label className={classes.input}>
					<input
						accept="/*"
						{...input}
						value={value}
						id="profilePicture"
						type={type}
						style={{ display: 'none' }}
					/>
					{label}
					{touched && error && <span className={classes.errorInputStyle}>{error}</span>}
				</label>
				<p className={classes.textHelper}>Size 1400 x 1400 px JPG or PNG format.</p>
			</div>
		</div>
	);
};

export default withStyles(styles)(InputFile);
