import React from 'react';

// Redux form
import { Field, reduxForm } from 'redux-form';
import { Grid, Col, Row } from 'react-flexbox-grid';
import Spotify from 'assets/img/Spotify.svg';
import Youtube from 'assets/img/Youtube.svg';
import Instagram from 'assets/img/intagram.svg';
import Soundcloud from 'assets/img/Souncloud.svg';
import InputField from '../Components/InputField';
import Title from '../../ApplyTitle';
import Subtitle from '../../ApplySubtitle';
import Button from '../Components/Button';
import { isValidURL } from '../../../containers/Wizard/validations/validate';

const SocialNetworks = (props) => {
	const { onSubmit } = props;
	return (
		<section style={{ marginTop: '3.125em' }}>
			<Grid>
				<Row center="xs">
					<Col xs={12} sm={12} md={6} lg={6}>
						<Title
							FirstSentence="Tell Us More"
							SecondSentence="About your music"
						/>
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={10} sm={10} md={10} lg={10}>
						<Subtitle Text="If you are not a soloist, enter the details of your label, band or group." />
					</Col>
				</Row>
				<Row center="xs">
					<Col xs={12} sm={12} md={6} lg={6}>
						<form onSubmit={onSubmit}>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="spotify"
									component={InputField}
									label="Spotify profile"
									validate={[isValidURL]}
									image={Spotify}
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="soundcloud"
									component={InputField}
									label="Sound Cloud Music"
									validate={[isValidURL]}
									image={Soundcloud}
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="youtube"
									component={InputField}
									label="Youtube Channel"
									validate={[isValidURL]}
									image={Youtube}
								/>
							</Col>
							<Col xs={12} sm={12} md={12} lg={12}>
								<Field
									name="instagram"
									component={InputField}
									label="Instagram profile"
									validate={[isValidURL]}
									image={Instagram}
								/>
							</Col>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									paddingLeft: '9px',
									marginBottom: '1.75',
									marginTop: '3em',
								}}
							>
								<span
									style={{
										color: '#FFA938',
										fontFamily: 'Graphik',
										fontSize: '12px',
										lineHeight: '13px',
									}}
								>
									Complete at least two inputs to continue.
								</span>
							</div>
							<div>
								<Button>Next</Button>
							</div>
						</form>
					</Col>
				</Row>
			</Grid>
		</section>
	);
};

export default reduxForm({
	form: 'wizard', //                 <------ same form name
	destroyOnUnmount: false, //        <------ preserve form data
	forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(SocialNetworks);
