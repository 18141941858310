import React from 'react';
import withStyles from 'react-jss';
import styles from './styles';

/**
 *
 * @param {FirstSentence} param0
 * @param {SecondSentence} param1
 */


const Title = ({
	classes, FirstSentence, SecondSentence, Align,
}) => {
	return (
		<div className={[Align ? classes.TextStyleLeft : classes.TextStyle]}>
			<h1 className={classes.FirstText}>{FirstSentence}</h1>
			<h1 className={classes.SecondText}>{SecondSentence}</h1>
		</div>
	);
};


export default withStyles(styles)(Title);
