const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default (async function showResults(values) {
	await sleep(500); // simulate server latency
	values.cityId = 1477074;
	values.countryId = 410;
	const comment = `${localStorage.getItem('Country')}-${localStorage.getItem(
		'City',
	)}`;
	values.comments = comment;
	const {
		spotify, soundcloud, instagram, youtube,
	} = values;
	if (spotify === '' || spotify === undefined) {
		values.spotify = 'https://www.spotify.com/hardcoded';
	}
	if (soundcloud === '' || soundcloud === undefined) {
		values.soundcloud = 'https://www.soundcloud.com/hardcoded';
	}
	if (instagram === '' || instagram === undefined) {
		values.instagram = 'https://www.instagram.com/hardcoded';
	}
	if (youtube === '' || youtube === undefined) {
		values.youtube = 'https://www.youtube.com/hardcoded';
	}

	values.social = {
		spotify: values.spotify,
		soundcloud: values.soundcloud,
		instagram: values.instagram,
		youtube: values.youtube,
	};
	values.pass2 = values.password;
	values.acceptTerms = true;
	values.bandName = values.name;
	values.type = 1;
	fetch(`${process.env.REACT_APP_API}pyro/register`, {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
		body: JSON.stringify(values),
	})
		.then(() => {
			window.location.href = '/';
		})
		.catch(() => {
		});
});
