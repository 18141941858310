import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import Landing from './Components/presentational/Landing';
import Apply from './Components/containers/Apply';
import Login from './Components/presentational/Login';
import Thanks from './Components/presentational/Thanks';
import Done from './Components/presentational/Done';
import Reset from './Components/presentational/ResetPassword';

const Routes = () => {
	return (
		<Switch>
			<Route exact path="/" component={Landing} />
			<Route path="/apply" component={Apply} />
			<Route path="/login" component={Login} />
			<Route path="/thanks" component={Thanks} />
			<Route path="/done" component={Done} />
			<Route path="/resetPassword" component={Reset} />
		</Switch>
	);
};

export default Routes;
