import React, { useState } from 'react';

// Wizard Components
import PersonalInformation from 'Components/presentational/Form/Step1';
import SocialNetworks from 'Components/presentational/Form/Step2';
import CreateAccount from 'Components/presentational/Form/Step3';
import Stepper from 'Components/presentational/Form/Components/Stepper';


const WizardForm = (props) => {
	const { onSubmit } = props;

	const [page, setPage] = useState(1);

	const nextPage = () => setPage(page + 1);

	const previousPage = () => setPage(page - 1);

	return (
		<>
			<Stepper currentStep={page} />
			{page === 1 && <PersonalInformation onSubmit={nextPage} />}
			{page === 2
				&& (
					<SocialNetworks
						previousPage={previousPage}
						onSubmit={nextPage}
					/>
				)}
			{page === 3
				&& (
					<CreateAccount
						previousPage={previousPage}
						onSubmit={onSubmit}
					/>
				)}

		</>
	);
};


export default WizardForm;
