import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';

// Redux stuff
import { Provider as StoreProvider } from 'react-redux';
import { theme } from 'theme/theme';
import App from './App';
import store from './redux/store';
//
import * as serviceWorker from './serviceWorker';

/**
 * (description)
 *  @constant
 * @type {component}
 * @name Application
 * is the main component
 */

const Application = () => {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</BrowserRouter>
	);
};

ReactDOM.render(
	<StoreProvider store={store}>
		<Application />
	</StoreProvider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
